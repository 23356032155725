import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider2 from "../components/slider2"

const pageName = "404 CONTENU INTROUVABLE"
const NotFoundPage = () => (
  <Layout>
    <SEO title={pageName} />
    <Slider2 title={pageName} />
    <div className="flex flex-wrap py-6">
      <div className="container mx-auto justify-center text-center">
        <span className="text-3xl text-blue-600 font-bold">
          404: Contenu introuvable
        </span>
        <p className="pt-2">
          Nous sommes désolés. Le contenu recherché est introuvable. 😭
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
